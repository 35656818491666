// Legend.tsx

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';

interface LegendProps {
  certificate: any;
}

interface LegendType {
  legend_id: string;
  legend_text: string;
  legend_code: string;
  effective_date: string;
  expiration_date: string;
}

export default function Legend({ certificate }: LegendProps) {
  const { auth } = useAuth();
  const [legends, setLegends] = useState<LegendType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchLegends = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/certificates/${certificate.certificate_id}/legends`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        setLegends(response.data.legends);
      } catch (err) {
        console.error('Error fetching legends:', err);
        setError('Failed to load legends.');
      } finally {
        setLoading(false);
      }
    };

    fetchLegends();
  }, [certificate.certificate_id]);

  if (loading) {
    return <div>Loading legends...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (legends.length === 0) {
    return <div>No legends associated with this certificate.</div>;
  }

  return (
    <div>
      {legends.map((legend) => (
        <div key={legend.legend_id} className="legend-box mb-3">
          <h6>{legend.legend_code || 'Legend'}</h6>
          <p>{legend.legend_text}</p>
          <p>
            <strong>Effective Date:</strong> {legend.effective_date}
          </p>
          {legend.expiration_date && (
            <p>
              <strong>Expiration Date:</strong> {legend.expiration_date}
            </p>
          )}
        </div>
      ))}
    </div>
  );
}
