import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import numbro from 'numbro';
import { contracts, formatOptions } from '../../../constants';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Badge,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import { useError } from '../../../contexts/ErrorContext';
import { explorerBaseUrl } from '../../../utils';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWriteContract,
  useWaitForTransactionReceipt,
  useReadContract,
} from 'wagmi';
import { Abi } from 'viem';

export default function Stock({ token }: any) {
  const { tokenId } = useParams();
  const { setError } = useError();
  const chainId = useChainId();
  const account = useAccount();
  const { auth } = useAuth();
  const [ocfVersion, setOcfVersion] = useState();
  const [loading, setLoading] = useState(false);
  const publicClient = usePublicClient();
  const [pauseTokenTxHash, setPauseTokenTxHash] = useState<
    `0x${string}` | undefined
  >();
  const [unpauseTokenTxHash, setUnpauseTokenTxHash] = useState<
    `0x${string}` | undefined
  >();

  const contract = contracts[chainId!].RestrictedStock;

  const { data: name } = useReadContract({
    address: tokenId as `0x${string}`,
    abi: contract.abi,
    functionName: 'name',
  });

  const { data: complianceAddress } = useReadContract({
    address: tokenId as `0x${string}`,
    abi: contract.abi,
    functionName: 'compliance',
  });

  const { data: identityRegistryAddress } = useReadContract({
    address: tokenId as `0x${string}`,
    abi: contract.abi,
    functionName: 'identityRegistry',
  });

  const { data: isAgent }: any = useReadContract({
    address: tokenId as `0x${string}`,
    abi: contract.abi,
    functionName: 'isAgent',
    args: [account.address],
  });

  const { data: ownerAddress } = useReadContract({
    address: tokenId as `0x${string}`,
    abi: contract.abi,
    functionName: 'owner',
  });

  const { data: paused, refetch } = useReadContract({
    address: tokenId as `0x${string}`,
    abi: contract.abi,
    functionName: 'paused',
  });

  const { data: totalSupply }: any = useReadContract({
    address: tokenId as `0x${string}`,
    abi: contract.abi,
    functionName: 'totalSupply',
  });

  const { data: version } = useReadContract({
    address: tokenId as `0x${string}`,
    abi: contract.abi,
    functionName: 'version',
  });

  const { data: classes }: any = useReadContract({
    address: tokenId as `0x${string}`,
    abi: contract.abi,
    functionName: 'getShareClasses',
  });

  const { data: modules, refetch: refetchModules } = useReadContract({
    address: '0x',
    abi: contracts[chainId!].ModularCompliance.abi,
    functionName: 'getModules',
  });

  const { writeContractAsync: pauseTokenWrite } = useWriteContract();

  // Wait for the pause transaction to be mined
  const { isLoading: isPauseTxLoading } = useWaitForTransactionReceipt({
    hash: pauseTokenTxHash,
  });

  const { writeContractAsync: unpauseTokenWrite } = useWriteContract();

  // Wait for the unpause transaction to be mined
  const { isLoading: isUnpauseTxLoading } = useWaitForTransactionReceipt({
    hash: unpauseTokenTxHash,
  });

  /**
   * Refetch pause/unpause status after done loading
   */
  useEffect(() => {
    if (!isUnpauseTxLoading || !isPauseTxLoading) refetch();
  }, [isUnpauseTxLoading, isPauseTxLoading]);

  const pauseToken = async () => {
    try {
      setLoading(true);
      const gas = await publicClient?.estimateContractGas({
        address: tokenId as `0x${string}`,
        abi: contract.abi as Abi,
        args: [],
        functionName: 'pause',
        account: account.address,
      });
      const hash = await pauseTokenWrite({
        address: tokenId as `0x${string}`,
        abi: contract.abi as Abi,
        args: [],
        gas,
        functionName: 'pause',
      });
      setPauseTokenTxHash(hash);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const unpauseToken = async () => {
    try {
      setLoading(true);
      const gas = await publicClient?.estimateContractGas({
        address: tokenId as `0x${string}`,
        abi: contract.abi as Abi,
        args: [],
        functionName: 'unpause',
        account: account.address,
      });
      const hash = await unpauseTokenWrite({
        address: tokenId as `0x${string}`,
        abi: contract.abi as Abi,
        args: [],
        functionName: 'unpause',
        gas,
        account: account.address,
      });
      setUnpauseTokenTxHash(hash);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const importCapTable = async (event: any) => {
    event.preventDefault();
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0]; // Assuming you're only interested in the first file

      // Handle file processing here...
      const formData = new FormData();
      formData.append('file', file);

      try {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth?.token}`,
          },
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/cap_tables`,
          formData,
          config
        );
        setOcfVersion(response.data.cap_table.ocf_version);
        console.log(response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
        setError(error);
      } finally {
        alert('Success');
      }
    }
  };

  useEffect(() => {
    if (complianceAddress) {
      refetchModules();
    }
  }, [complianceAddress, refetchModules]);

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <Card>
            <Card.Header as="h4" className="p-3 d-flex justify-content-between">
              <div>{name || 'Token Information'}</div>
            </Card.Header>
            <Card.Body className="p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                  Status:{' '}
                  <Badge bg={paused ? 'danger' : 'success'}>
                    {paused ? 'PAUSED' : 'LIVE'}
                  </Badge>
                </div>
                {isAgent?.toString() === 'true' &&
                  (paused ? (
                    <Button
                      variant="success"
                      onClick={unpauseToken}
                      disabled={loading || isUnpauseTxLoading}
                    >
                      {loading || isUnpauseTxLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Loading...</span> Unpause
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faPlay} /> Unpause
                        </>
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="warning"
                      onClick={pauseToken}
                      disabled={loading || isPauseTxLoading}
                    >
                      {loading || isPauseTxLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Loading...</span> Pause
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faPause} /> Pause
                        </>
                      )}
                    </Button>
                  ))}
              </div>
              <hr />
              {/* <div className="d-flex flex-row justify-content-between align-items-center ">
                <div>
                  <Card.Text>OCF Version: {ocfVersion}</Card.Text>
                </div>
                <div> */}
              {/* Invisible file input */}
              {/* <input
                    type="file"
                    id="file-input"
                    accept=".zip"
                    style={{ display: 'none' }}
                    onChange={importCapTable}
                    onClick={(event) => event.stopPropagation()}
                  /> */}

              {/* Label acting as the button to open the file picker */}
              {/* <label
                    htmlFor="file-input"
                    className="btn btn-dark"
                    onClick={(event) => event.stopPropagation()}
                  >
                    Import OCF <i className="fa fa-download"></i>
                  </label>
                </div>
              </div>
              <hr /> */}
              <Card.Text>Token Version: {version}</Card.Text>
              <hr />
              <Card.Text>
                Total Supply:{' '}
                {numbro(totalSupply?.toString()).format(formatOptions)} shares
              </Card.Text>
              <hr />
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="">
                  <Card.Text>
                    Share Classes: {classes?.length?.toString() || '0'}
                  </Card.Text>
                </div>
                <NavLink to="share-types" className="btn btn-primary">
                  Manage
                </NavLink>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="col-3">Owner Address: </div>
                <div className="col-6 text-truncate">
                  <Link
                    target="_blank"
                    to={`${explorerBaseUrl[chainId!]}/address/${ownerAddress}`}
                  >
                    {ownerAddress}
                  </Link>
                </div>
                <div className="col-3"></div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="col-3">Token Contract: </div>
                <div className="col-6 text-truncate">
                  <Link
                    target="_blank"
                    to={`${explorerBaseUrl[chainId!]}/address/${tokenId}`}
                  >
                    {tokenId}
                  </Link>
                </div>
                <div className="col-3"></div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="col-3">Module Registry: </div>
                <div className="col-6 text-truncate">
                  <Link
                    target="_blank"
                    to={`${explorerBaseUrl[chainId!]}/address/${complianceAddress}`}
                  >
                    {complianceAddress}
                  </Link>
                </div>
                <div className="col-3 text-end">
                  <NavLink to="compliance" className="btn btn-primary">
                    Manage
                  </NavLink>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="col-3">Shareholder Registry: </div>
                <div className="col-6 text-truncate">
                  <Link
                    target="_blank"
                    to={`${explorerBaseUrl[chainId!]}/address/${identityRegistryAddress}`}
                  >
                    {identityRegistryAddress}
                  </Link>
                </div>
                <div className="col-3 text-end">
                  <NavLink to="identities" className="btn btn-primary">
                    Manage
                  </NavLink>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
