// CertificateList.tsx

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { useChainId, useAccount } from 'wagmi';
import CertificateListItem from './CertificateListItem';

export default function CertificateList() {
  const { auth } = useAuth();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const [certificates, setCertificates] = useState<any[]>([]);
  const [logos, setLogos] = useState<{ [key: string]: string }>({});

  /**
   * Fetch certificates
   */
  useEffect(() => {
    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/v1/accounts/me/certificates`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        const result = response.data.result;
        setCertificates(result);

        // Fetch corporate logos for each certificate
        const logoPromises = result.map(async (certificate: any) => {
          const logoResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/business_entities/${certificate.issuer_id}/logo`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
              responseType: 'arraybuffer',
            }
          );
          const contentType = logoResponse.headers['content-type'];
          const base64Logo = Buffer.from(logoResponse.data, 'binary').toString(
            'base64'
          );
          const logoSrc = `data:${contentType};base64,${base64Logo}`;

          return { issuerId: certificate.issuer_id, logoSrc };
        });

        const logosData = await Promise.all(logoPromises);
        const logoMap: { [key: string]: string } = {};
        logosData.forEach(({ issuerId, logoSrc }) => {
          logoMap[issuerId] = logoSrc;
        });

        setLogos(logoMap);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token, chainId, account]);

  return (
    <section className="pb-4">
      {certificates.map((certificate: any, index: number) => {
        if (certificate.canceled_date) {
          return null;
        }
        const logoUrl =
          logos[certificate.issuer_id] || 'https://placehold.co/50';
        return (
          <CertificateListItem
            key={index}
            certificate={{ ...certificate, logoUrl }}
          />
        );
      })}
      {certificates.length === 0 && (
        <span className="text-muted">No certificates to display</span>
      )}
    </section>
  );
}
