import { useEffect, useState } from 'react';
import { Badge, ListGroup, Button, Card, Row, Col } from 'react-bootstrap';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWriteContract,
} from 'wagmi';
import { Abi, formatUnits } from 'viem';
import axios from 'axios';
import { contracts } from '../../constants';
import { maxBlockRange } from '../../utils';
import { useError } from '../../contexts/ErrorContext';
import { useAuth } from '../../contexts/AuthContext';

type Bid = {
  id: number;
  listingId: number;
  token: string;
  price: bigint;
  quantity: number;
  bidder: string;
  expiresAt: number;
  status: string;
};

export default function Bids() {
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const [bids, setBids] = useState<Bid[]>([]);
  const { setError } = useError();
  const { auth } = useAuth();
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [selectedBidId, setSelectedBidId] = useState<number | null>(null);
  const [showBids, setShowBids] = useState<boolean>(true);

  useEffect(() => {
    async function fetchBids() {
      try {
        if (!chainId || !account) return;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/markets/bids`,
          {
            params: { bidder_id: account },
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        const fetchedBids = response.data.bids;

        // Map the fetched bids to your Bid type
        const parsedBids: Bid[] = fetchedBids.map((bid: any) => ({
          id: bid.bid_id,
          listingId: bid.listing_id,
          token: bid.base_token_address,
          price: bid.bid_price,
          quantity: bid.quantity,
          bidder: bid.bidder_id,
          expiresAt: new Date(bid.expires_at).getTime() / 1000,
          status: bid.status,
        }));

        setBids(parsedBids);
      } catch (error) {
        setError(error);
        console.error('Error fetching bids:', error);
      }
    }

    if (account) {
      fetchBids();
    }
  }, [account, chainId, publicClient]);

  const { writeContractAsync: cancelBidWrite } = useWriteContract();

  const cancelBid = async (bid: Bid) => {
    setIsCancelling(true);
    setSelectedBidId(bid.id);

    try {
      if (!chainId || !account) return;

      const params = [bid.listingId, bid.id];

      const gasEstimate = await publicClient?.estimateContractGas({
        account,
        address: contracts[chainId!].Auction.address as `0x${string}`,
        abi: contracts[chainId!].Auction.abi as Abi,
        functionName: 'cancelBid',
        args: params,
      });

      const hash = await cancelBidWrite({
        address: contracts[chainId!].Auction.address as `0x${string}`,
        abi: contracts[chainId!].Auction.abi as Abi,
        functionName: 'cancelBid',
        args: params,
        gas: gasEstimate,
        account,
      });

      // Wait for transaction confirmation
      await publicClient?.waitForTransactionReceipt({ hash });

      // After successful on-chain cancellation, update the database
      await cancelBidInDatabase(bid.id);

      // Update the UI
      setBids((prevBids) =>
        prevBids.map(
          (b) => (b.id === bid.id ? { ...b, status: 'Withdrawn' } : b) // Set status to 'Withdrawn'
        )
      );
    } catch (error) {
      setError(error);
      console.error('Error cancelling bid:', error);
    } finally {
      setIsCancelling(false);
      setSelectedBidId(null);
    }
  };

  const cancelBidInDatabase = async (bidId: number) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/markets/bids/${bidId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
    } catch (error) {
      setError(error);
      console.error('Error updating bid status in database:', error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h5>
          Your Bids{' '}
          {bids.length > 0 && (
            <div className="badge bg-primary">{bids.length}</div>
          )}
        </h5>
        <div>
          <button className="btn btn-sm" onClick={() => setShowBids(!showBids)}>
            <i className={`fa fa-caret-${showBids ? 'down' : 'up'}`} />
          </button>
        </div>
      </div>
      {showBids &&
        (bids?.length === 0 ? (
          <p className="text-muted">No bids to display</p>
        ) : (
          <div className="card-container">
            {bids.map((bid) => (
              <Card key={bid.id} className="mb-3 shadow-sm rounded">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <h6 className="mb-2">
                        <strong>Bid on Listing #{bid.listingId}</strong>
                      </h6>
                      <p className="mb-1">
                        <strong>Token:</strong> {bid.token}
                      </p>
                      <p className="mb-1">
                        <strong>Price:</strong> $
                        {bid.price ? formatUnits(bid.price, 2) : ''}/sh
                      </p>
                      <p className="mb-1">
                        <strong>Quantity:</strong> {bid.quantity}
                      </p>
                      <p className="mb-1">
                        <strong>Expires:</strong>{' '}
                        {new Date(bid.expiresAt * 1000).toLocaleString()}
                      </p>
                    </Col>
                    <Col md={4} className="text-md-end">
                      <Badge
                        bg={
                          bid.status !== 'Withdrawn' ? 'primary' : 'secondary'
                        }
                        className="mb-2"
                      >
                        {bid.status}
                      </Badge>
                      <br />
                      {bid.status === 'Active' && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => cancelBid(bid)}
                          disabled={isCancelling && selectedBidId === bid.id}
                        >
                          {isCancelling && selectedBidId === bid.id
                            ? 'Cancelling...'
                            : 'Cancel Bid'}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </div>
        ))}
    </>
  );
}
