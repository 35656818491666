// VestingSchedule.tsx

import React, { useEffect, useState } from 'react';
import { useChainId, usePublicClient } from 'wagmi';
import { contracts } from '../../../constants';
import { Table, ProgressBar } from 'react-bootstrap';

export default function VestingSchedule({ certificate }: { certificate: any }) {
  const [vestingSchedule, setVestingSchedule] = useState<any>(null);
  const [conditionsMet, setConditionsMet] = useState<{
    [key: string]: boolean;
  }>({});
  const publicClient = usePublicClient();
  const chainId = useChainId();

  useEffect(() => {
    (async () => {
      try {
        // Fetch the vesting schedule
        const schedule: any = await publicClient?.readContract({
          address: contracts[chainId!].VestingModule.address as `0x${string}`,
          abi: contracts[chainId!].VestingModule.abi,
          functionName: 'getVestingSchedule',
          args: [certificate.security_id],
        });

        // Destructure the schedule data
        console.log('schedule', schedule);
        const [totalAmount, vestedAmount, startDate, terms] = schedule;
        const { name, description, allocationType, conditions } = terms;

        // Fetch whether each condition has been met
        const conditionStatuses = await Promise.all(
          conditions.map(async (condition: any) => {
            const isMet = await publicClient?.readContract({
              address: contracts[chainId!].VestingModule
                .address as `0x${string}`,
              abi: contracts[chainId!].VestingModule.abi,
              functionName: 'isConditionMet',
              args: [certificate.security_id, condition.id],
            });
            return { id: condition.id, isMet };
          })
        );

        // Map conditions to their met status
        const conditionsMetMap: { [key: string]: boolean } = {};
        conditionStatuses.forEach(({ id, isMet }) => {
          conditionsMetMap[id] = isMet;
        });

        setVestingSchedule({
          totalAmount,
          vestedAmount,
          startDate,
          terms: { name, description, allocationType, conditions },
        });
        setConditionsMet(conditionsMetMap);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [certificate.security_id, chainId]);

  if (!vestingSchedule) {
    return <div>Loading vesting schedule...</div>;
  }

  // If there's no vesting schedule, display a message that shares are fully vested
  if (!vestingSchedule?.startDate) {
    return (
      <div>
        <h4>Vesting Schedule</h4>
        <p>All shares are fully vested.</p>
      </div>
    );
  }

  const { totalAmount, vestedAmount, startDate, terms } = vestingSchedule;
  const { name, description, allocationType, conditions } = terms;

  // Compute fully vested date and check for cliff
  let fullyVestedDate = Number(startDate);
  let hasCliff = false;
  let cliffDescription = '';
  let vestingDescription = description || ''; // For simplicity

  conditions.forEach((condition: any) => {
    if (Number(condition.triggerDate) > fullyVestedDate) {
      fullyVestedDate = Number(condition.triggerDate);
    }
    if (condition.id === 'cliff') {
      hasCliff = true;
      cliffDescription = condition.description;
    }
  });

  // Format dates
  const vestingStartDate = new Date(
    Number(startDate) * 1000
  ).toLocaleDateString();
  const fullyVestedDateFormatted = new Date(
    fullyVestedDate * 1000
  ).toLocaleDateString();

  // Progress calculation
  const progressPercent = (Number(vestedAmount) / Number(totalAmount)) * 100;

  // Build table data
  let cumulativeVested = BigInt(0);
  const tableRows = conditions.map((condition: any, index: number) => {
    let sharesVested = BigInt(0);

    if (BigInt(condition.quantity) > BigInt(0)) {
      sharesVested = BigInt(condition.quantity);
    } else {
      const totalVestingAmount =
        BigInt(condition.portionDenominator) === BigInt(0)
          ? BigInt(totalAmount) - cumulativeVested
          : BigInt(totalAmount);
      sharesVested =
        (totalVestingAmount * BigInt(condition.portionNumerator)) /
        BigInt(condition.portionDenominator);
    }
    cumulativeVested += sharesVested;

    const isMet = conditionsMet[condition.id];

    return {
      period: index + 1,
      date: new Date(Number(condition.triggerDate) * 1000).toLocaleDateString(),
      sharesVested: sharesVested.toString(),
      cumulativeVested: cumulativeVested.toString(),
      isMet,
    };
  });

  return (
    <div>
      <h4>Vesting Schedule</h4>

      {/* Summary Section */}
      <h5>Summary</h5>
      <Table bordered>
        <tbody>
          <tr>
            <th>Schedule Name</th>
            <td>{name}</td>
          </tr>
          <tr>
            <th>Vesting Start</th>
            <td>{vestingStartDate}</td>
          </tr>
          <tr>
            <th>Fully Vested Date</th>
            <td>{fullyVestedDateFormatted}</td>
          </tr>
          <tr>
            <th>Cliff</th>
            <td>{hasCliff ? 'Yes' : 'No'}</td>
          </tr>
          {hasCliff && (
            <tr>
              <th>Cliff Description</th>
              <td>{cliffDescription}</td>
            </tr>
          )}
          <tr>
            <th>Vesting</th>
            <td>{vestingDescription}</td>
          </tr>
        </tbody>
      </Table>

      {/* Progress Section */}
      <h5>Progress</h5>
      <p>
        {Number(vestedAmount)} of {Number(totalAmount)} shares (
        {progressPercent.toFixed(2)}%) in {certificate.label} have vested.
      </p>
      <ProgressBar
        now={progressPercent}
        label={`${progressPercent.toFixed(2)}%`}
      />

      {/* Legend */}
      <p>
        <strong>Legend:</strong>{' '}
        <span style={{ color: '#0d6efd' }}>Vested</span>,{' '}
        <span style={{ textDecoration: 'line-through', color: '#dc3545' }}>
          Terminated
        </span>
      </p>

      {/* Vesting Table */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Period</th>
            <th>Date</th>
            <th>Shares Vested</th>
            <th>Cumulative Vested</th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row: any) => {
            let rowStyle = {};
            if (row.isMet) {
              rowStyle = { color: '#0d6efd' }; // Bootstrap 'primary' color
            }
            // You can add logic for terminated conditions here

            return (
              <tr key={row.period} style={rowStyle}>
                <td>{row.period}</td>
                <td>{row.date}</td>
                <td>{row.sharesVested}</td>
                <td>{row.cumulativeVested}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
