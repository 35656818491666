import React, { useState, useEffect } from 'react';
import {
  useWriteContract,
  useWaitForTransactionReceipt,
  useChainId,
  useAccount,
  usePublicClient,
} from 'wagmi';
import Container from 'react-bootstrap/Container';
import { contracts } from '../../constants';
import Form from 'react-bootstrap/Form';
import { Button, Card, InputGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { useError } from '../../contexts/ErrorContext';
import { useNavigate, useParams } from 'react-router-dom';

function RegisterIdentity() {
  const chainId = useChainId();
  const { address: account } = useAccount();
  const [email, setEmail] = useState('');
  const [identityAddress, setIdentityAddress] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const { auth } = useAuth();
  const [identityRegistry, setIdentityRegistry] = useState<
    string | undefined
  >();
  const { setError } = useError();
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const [loading, setLoading] = useState(false);
  const publicClient = usePublicClient();

  /**
   * @function fetchUserDetails
   * @description Fetches identity and wallet address based on email
   */
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/identities/search?email=${encodeURIComponent(email)}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      const { identity_address, wallet_address } = response.data.result;
      setIdentityAddress(identity_address);
      setWalletAddress(wallet_address);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
      setError(error);
    }
  };

  /**
   * Set token registry address
   */
  useEffect(() => {
    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens?chain_id=${chainId}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        const result = response.data.result;
        setIdentityRegistry(result[0].token_registry);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token, chainId]);

  const {
    data: registerIdentityTxHash,
    writeContractAsync: registerIdentity,
    isPending: isRegisterIdentityLoading,
  } = useWriteContract();

  const {
    data: registerIdentityReceipt,
    isLoading: isRegisterIdentityTxLoading,
  } = useWaitForTransactionReceipt({
    hash: registerIdentityTxHash,
  });

  /**
   * @function handleRegister
   * @param event
   * @returns
   */
  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!account || !chainId || !walletAddress || !identityAddress) {
      alert('Please check your wallet connection and input.');
      return;
    }

    try {
      const args: any = [
        walletAddress as `0x${string}`,
        identityAddress as `0x${string}`,
        42,
      ];
      const gas = await publicClient?.estimateContractGas({
        account,
        address: identityRegistry as `0x${string}`,
        abi: contracts[chainId!]?.IdentityRegistry.abi,
        functionName: 'registerIdentity',
        args,
      });
      const result = await registerIdentity({
        account,
        address: identityRegistry as `0x${string}`,
        abi: contracts[chainId!]?.IdentityRegistry.abi,
        functionName: 'registerIdentity',
        args,
        gas,
      });

      // Add account to token identity registry in database
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens/${tokenId}/identities`,
        {
          wallet_address: walletAddress,
          identity_address: identityAddress,
          chain_id: chainId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      console.log({ response });

      alert('Identity registered successfully.');
      setIdentityAddress('');
      setWalletAddress('');
      navigate(-1);
    } catch (error) {
      console.error('Registration failed:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {/* Breadcrumb */}
      <div className="border-bottom py-3 mb-4">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/equity/${tokenId}`}>{tokenId}</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/equity/${tokenId}/identities`}>Registry</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Register User
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Card className="col-4 offset-4 mt-5">
        <Card.Header>
          <h4 className="mb-0">Register User</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleRegister}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email Address</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button variant="outline-secondary" onClick={fetchUserDetails}>
                  Search
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="identityAddress">
              <Form.Label>User's Identity Contract</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={identityAddress}
                onChange={(e) => setIdentityAddress(e.target.value)}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="walletAddress">
              <Form.Label>User's Wallet Address</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="countryCode">
              <Form.Label>Country</Form.Label>
              <Form.Select aria-label="Country select" disabled value="42">
                <option value="42">United States</option>
                {/* Even though the select is disabled and has only one option, 
                    it's important to include the value attribute matching the option's value. */}
              </Form.Select>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={
                loading ||
                isRegisterIdentityLoading ||
                isRegisterIdentityTxLoading
              }
            >
              {loading ||
              isRegisterIdentityLoading ||
              isRegisterIdentityTxLoading ? (
                <span className="fa fa-spin fa-spinner" />
              ) : (
                'Register Identity'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default RegisterIdentity;
